<template>
  <div>
    <div>
      <h3 v-if="system">{{ $t("profile.roles.system") }}</h3>
      <div class="tags-container" v-for="role in systemRoles" :key="role.id">
        <div class="tag">
          <div class="tag-label">{{ roleLabel(role) }}</div>
        </div>
      </div>
    </div>
    <div v-if="pool">
      <h3>{{ $t("profile.roles.pool") }}</h3>
      <div class="tags-container tags-blue" v-for="role in pool" :key="role.id">
        <div class="tag">
          <div class="tag-label">{{ poolRoleLabel(role) }}</div>
          <div class="cancel" @click="removeRole(role)">X</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AccountRoles",
  computed: {
    ...mapGetters({
      system: "user/roles/system",
      pool: "user/roles/pool",
    }),
    systemRoles() {
      return this.system.filter((el) => {
        const s = el.name.split("_");
        return s.length == 1 || (s.length > 1 && s[0] == "pool");
      });
    },
  },
  methods: {
    roleLabel(role) {
      return this.$t("system_roles." + role.name);
    },
    poolRoleLabel(role) {
      return this.$t("users.list.roles." + role.name);
    },
    removeRole(value) {
      if (!confirm(this.$t("profile.edit.roles.confirm"))) {
        return false;
      }
      this.$store.commit("user/roles/current", value);
      this.$store.dispatch("user/roles/demote").then(() => {
        this.$store.dispatch("refresh");
      });
    },
  },
};
</script>
