<template>
  <div>
    <vca-field :label="$t('deposit.edit.info')">
      <h3>{{ $t("deposit.edit.crew") }} {{ value.crew.name }}</h3>
      <h3>
        {{ $t("deposit.edit.reason_for_payment") }}
        {{ value.reason_for_payment }}
      </h3>
    </vca-field>
    <vca-field :label="$t('deposit.add.takings')">
      <div v-if="!value.deposit_units.length">
        {{ $t("deposit.add.description") }}
      </div>
      <div v-else style="color: black; float: right">
        <vca-info-box>{{ $t("deposit.add.description") }}</vca-info-box>
      </div>
      <vca-row :key="index" v-for="(res, index) in value.deposit_units">
        <span> {{ res.taking.name }} </span>
        <span>{{ formatMoney(res.money) }}</span>
      </vca-row>
    </vca-field>
    <vca-field :label="$t('deposit.info.header')">
      <DepositFinanceInfo v-model="value" />
    </vca-field>
    <vca-field :label="$t('deposit.external.header')">
      <DepositExternalInfo
        v-if="value.has_external"
        v-model="value.external"
        :reason_for_payment="value.reason_for_payment"
      />
    </vca-field>
    <vca-field v-if="has_receipts" :label="$t('deposit.receipt.header')">
      <ReceiptDisplay
        v-model="value.receipts"
        :displayDelete="false"
        :name="value.reason_for_payment"
        :id="value.id"
      >
      </ReceiptDisplay>
    </vca-field>
  </div>
</template>
<script>
import DepositExternalInfo from "./DepositExternalInfo.vue";
import DepositFinanceInfo from "./DepositFinanceInfo.vue";
import ReceiptDisplay from "./ReceiptDisplay.vue";
export default {
  name: "DepositInfo",
  props: {
    value: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    has_receipts() {
      return this.value.has_external && this.value.receipts.length > 0;
    },
  },
  components: { DepositExternalInfo, ReceiptDisplay, DepositFinanceInfo },
};
</script>
