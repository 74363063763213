<template>
  <vca-field :label="$t('events.general.header')">
    <div>
      <h3>{{ $t("events.type_of_event.label") }}</h3>
      <vca-dropdown
        v-if="!value.isPublished || hasSystemPermission()"
        ref="type_of_event"
        v-model="type_of_event"
        :options="eventtypes"
        :rules="$v.value.type_of_event"
        label=""
        :errorMsg="$t('events.type_of_event.errorMsg')"
        :placeholder="$t('events.type_of_event.placeholder')"
      />
      <div style="padding: 10px 0" v-else>
        {{ $t("events.eventtypes." + value.type_of_event + ".title") }}
      </div>
    </div>
    <div v-if="!isTour">
      <h3>
        {{ $t("events.location.label") }}
        <span v-if="value.location && value.location.name"
          >({{ $t("events.location.current") }}{{ value.location.name }},
          {{ value.location.city }})</span
        >
      </h3>
      <div>
        <vca-row>
          <vca-location
            ref="location"
            v-model="value.location"
            :errorMsg="$t('events.location.errorMsg')"
            :rules="$v.value.location"
            type="establishment"
          />
          <div class="inline-infobox">
            <vca-info-box>{{ $t("events.location.info") }}</vca-info-box>
          </div>
        </vca-row>
      </div>
      <h3>
        {{ $t("events.meeting_url.label") }}
      </h3>
      <div>
        <vca-row>
          <vca-input
            ref="meeting_url"
            v-model="value.meeting_url"
            :rules="$v.value.meeting_url"
            :errorMsg="$t('events.meeting_url.errorMsg')"
            :placeholder="$t('events.meeting_url.placeholder')"
          />
          <div class="inline-infobox">
            <vca-info-box>{{ $t("events.meeting_url.info") }}</vca-info-box>
          </div>
        </vca-row>
      </div>
    </div>
    <div>
      <h3>{{ $t("events.name.label") }}</h3>
      <vca-input
        ref="name"
        v-model="value.name"
        :rules="$v.value.name"
        :errorMsg="$t('events.name.errorMsg')"
        :placeholder="$t('events.name.placeholder')"
      />
    </div>
    <div v-if="hasArtist">
      <h3>{{ $t("events.artist.label") }}</h3>
      <div>
        <vca-row>
          <ArtistDropdown v-model="value" />
          <div class="inline-infobox">
            <vca-info-box>{{ $t("events.artist.info") }}</vca-info-box>
          </div>
        </vca-row>
      </div>
    </div>
    <div v-if="hasOrganizer">
      <h3>{{ $t("events.organizer.label") }}</h3>
      <div>
        <vca-row>
          <OrganizerDropdown v-model="value" />
          <div class="inline-infobox">
            <vca-info-box>{{ $t("events.organizer.info") }}</vca-info-box>
          </div>
        </vca-row>
      </div>
    </div>
    <div>
      <h3>{{ $t("events.website.label") }}</h3>
      <vca-input
        v-model="value.website"
        :placeholder="$t('events.website.placeholder')"
      />
    </div>
    <div>
      <h3>{{ $t("events.additional.label") }}</h3>
      <vca-textarea
        v-model="value.additional_information"
        :placeholder="$t('events.additional.placeholder')"
      />
    </div>
  </vca-field>
</template>

<script>
import ArtistDropdown from "@/components/events/form/ArtistDropdown";
import OrganizerDropdown from "@/components/events/form/OrganizerDropdown";
import { mapGetters } from "vuex";
export default {
  name: "EventsFormGeneral",
  components: { ArtistDropdown, OrganizerDropdown },
  data() {
    return {
      list: [],
    };
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  validations() {
    return this.validation;
  },
  watch: {
    api_lang: {
      handler(value) {
        this.$refs.location.setLanguage(value);
      },
    },
  },
  methods: {
    validate_type() {
      if (!this.value.isPublished || this.hasSystemPermission()) {
        this.$refs.type_of_event.validate();
      }
    },
    validate() {
      if (!this.value.isPublished || this.hasSystemPermission()) {
        this.$refs.type_of_event.validate();
      }
      this.$refs.name.validate();
      this.$refs.meeting_url.validate();
      if (!this.isTour) {
        this.$refs.location.validate();
      }
    },
  },
  computed: {
    hasOrganizer() {
      return this.organizerTypes.includes(this.value.type_of_event);
    },
    hasArtist() {
      return this.artistTypes.includes(this.value.type_of_event);
    },
    isTour() {
      return this.value.type_of_event == "tour";
    },
    type_of_event: {
      get() {
        return [{ value: this.value.type_of_event }];
      },
      set(value) {
        let newValue = this.value;
        newValue.type_of_event = value.length ? value[0].value : null;
        this.value = newValue;
      },
    },
    eventtypes: {
      get() {
        //var that = this
        return this.event_types.filter((el) => {
          if (
            !this.hasSystemPermission() &&
            ["tour", "goldeimer", "nwgathering"].includes(el.value)
          ) {
            return false;
          }
          el.title = this.$t(el.title);
          el.subtitle = this.$t(el.subtitle);
          el.label = this.$t(el.label);
          return true;
        });
      },
    },
    ...mapGetters({
      api_lang: "api_lang",
      validation: "events/validations",
      event_types: "events/eventtypes",
      hasSystemPermission: "user/roles/hasSystemPermission",
      artistTypes: "events/artistTypes",
      organizerTypes: "events/organizerTypes",
    }),
  },
};
</script>
