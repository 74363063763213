<template>
  <vca-field
    v-if="value.internal_asp && value.internal_asp.id"
    :label="$t('events.internal_asp.header')"
  >
    <div>{{ $t("events.internal_asp.description") }}</div>
    <vca-card class="shadowed">
      <vca-column>
        <h4>{{ $t("events.internal_asp.name.label") }}</h4>
        <div class="vertical-center">
          <span class="bold">{{ display_name }}</span>
          ({{ full_name }})
        </div>
        <h4>{{ $t("events.internal_asp.email.label") }}</h4>
        <div class="vertical-center">
          <span class="bold">{{ email }}</span>
        </div>
        <h4>{{ $t("events.internal_asp.phone.label") }}</h4>
        <div class="vertical-center">
          <span class="bold">{{ phone }}</span>
        </div>
      </vca-column>
    </vca-card>
  </vca-field>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "InternalAspDisplay",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  computed: {
    full_name() {
      return (
        this.value.internal_asp.first_name +
        " " +
        this.value.internal_asp.last_name
      );
    },
    display_name() {
      return this.value.internal_asp.display_name;
    },
    email() {
      return this.value.internal_asp.email
        ? this.value.internal_asp.email
        : " - ";
    },
    phone() {
      return this.value.internal_asp.profile.phone
        ? this.value.internal_asp.profile.phone
        : " - ";
    },
    ...mapGetters({
      organisations: "organisations/list",
    }),
  },
};
</script>
