<template>
  <vca-column>
    <vca-column>
      <h3>{{ $t("events.list.manage_filter.header") }}</h3>
      <vca-row>
        <vca-checkbox
          v-for="event_state in eventStates"
          v-model="filter.event_state"
          :key="event_state"
          :id="event_state"
          >{{ $t("events.list.event_states." + event_state) }}</vca-checkbox
        >
      </vca-row>

      <div>
        <vca-checkbox v-model="filter.only_apply">{{
          $t("events.list.date_filter.only_apply")
        }}</vca-checkbox>
      </div>
      <div
        v-if="hasPoolPermission(poolEventPermissions) || hasSystemPermission()"
      >
        <vca-checkbox v-model="filter.missing_applications">{{
          $t("events.list.missing_applications")
        }}</vca-checkbox>
      </div>
      <div><hr /></div>
    </vca-column>
    <vca-row>
      <!--vca-card>
        <h3>{{ $t("events.list.entity_filter.header") }}</h3>
        <vca-checkbox
          v-for="entity in getCountrys"
          v-model="filter.entities"
          :key="entity"
          :id="entity"
          >{{ entity }}</vca-checkbox
        >
      </vca-card-->
      <vca-card>
        <h3>{{ $t("events.list.crew.header") }}</h3>
        <vca-dropdown
          v-model="selectedCrews"
          :multiple="true"
          :options="filteredCrewList"
          label=""
          :placeholder="$t('users.list.crew.select.placeholder')"
        ></vca-dropdown>
        <vca-field-row v-if="user">
          <button
            v-if="user.crew.id != ''"
            class="vca-button-small"
            @click="addOwnCrew()"
          >
            {{
              $t("context_button.add", {
                0: $t("events.list.crew.own"),
              })
            }}
          </button>
          <button
            v-if="user.crew.id != ''"
            class="vca-button-small"
            @click="setOwnCrew()"
          >
            {{
              $t("context_button.select", {
                0: $t("events.list.crew.own"),
              })
            }}
          </button>
        </vca-field-row>
      </vca-card>
      <vca-card v-if="hasSystemPermission()">
        <div>
          <h3>{{ $t("network.crews.list.organisation_filter.header") }}</h3>
          <vca-checkbox
            v-for="org in organisationList"
            v-model="filter.organisation_id"
            :key="org.id"
            :id="org.id"
          >
            {{ org.name }}
          </vca-checkbox>
        </div>
      </vca-card>
    </vca-row>
    <vca-row>
      <vca-card>
        <h3>{{ $t("events.list.date_filter.header") }}</h3>
        <div>
          <vca-field-row>
            <vca-input-date
              ref="start_at"
              v-model="filter.start_at"
              :default="Date.now() / 1000"
              class="margin-cancel-input"
              :placeholder="$t('events.list.date_filter.start')"
              :language="this.$i18n.locale"
              first
            />
            <IconButton
              type="cancel"
              @click="clearStartDate()"
              :title="$t('button.remove')"
            />
          </vca-field-row>
          <vca-field-row>
            <vca-input-date
              ref="end_at"
              v-model="filter.end_at"
              :default="Date.now() / 1000"
              clearable
              class="margin-cancel-input"
              :language="this.$i18n.locale"
              :placeholder="$t('events.list.date_filter.end')"
              first
            />
            <IconButton
              type="cancel"
              @click="clearEndDate()"
              :title="$t('button.remove')"
            />
          </vca-field-row>
        </div>
      </vca-card>
      <vca-card>
        <h3>{{ $t("events.list.type_filter.header") }}</h3>
        <vca-checkbox
          v-for="eventtype in eventtypes"
          v-model="filter.type"
          :key="eventtype.value"
          :id="eventtype.value"
          >{{
            $t("events.eventtypes." + eventtype.value + ".title")
          }}</vca-checkbox
        >
      </vca-card>
    </vca-row>
  </vca-column>
</template>
<script>
import IconButton from "@/components/utils/IconButton";
import { mapGetters } from "vuex";
export default {
  name: "EventFilter",
  components: { IconButton },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  watch: {
    filter: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },
  methods: {
    setOwnCrew() {
      this.filter.crew_id = [this.user.crew.crew_id];
    },
    addOwnCrew() {
      if (!this.filter.crew_id.find((el) => el == this.user.crew.crew_id)) {
        this.filter.crew_id.push(this.user.crew.crew_id);
      }
    },
    clearEndDate() {
      this.$refs.end_at.clearDate();
    },
    clearStartDate() {
      this.$refs.start_at.clearDate();
    },
  },
  computed: {
    filter: {
      get() {
        return this.value;
      },
    },
    organisationList: {
      get() {
        return this.$store.state.organisations.list.filter((el) => {
          el.title = el.name;
          el.label = el.name;
          el.value = el.id;
          return true;
        });
      },
    },
    selectedCrews: {
      get() {
        return this.filteredCrewList.filter((el) => {
          return this.filter.crew_id.find((crew) => crew == el.id) != undefined;
        });
      },
      set(value) {
        if (value) {
          console.log(value);
          this.filter.crew_id = value.map((el) => el.id);
        } else {
          this.filter.crew_id = [];
        }
      },
    },
    filteredCrewList() {
      // We currently filter for german crews
      let de_crews = this.crewList.filter((row) => {
        var hasGermanCity = row.cities.find((city) =>
          ["Deutschland", "Germany"].includes(city.country)
        );
        return hasGermanCity != undefined;
      });
      de_crews.push({
        id: null,
        title: this.$t("events.list.crew.office_hh"),
        label: this.$t("events.list.crew.office_hh"),
        subtitle: this.$t("country.germany"),
      });
      return de_crews;
    },
    // Todo filter locations
    getCountrys() {
      if (!this.events) {
        return [];
      }
      const unique = [
        ...new Set(
          this.events.map((item) =>
            item.location.country != ""
              ? item.location.country
              : this.$t("events.list.entity_filter.no_country")
          )
        ),
      ]; // [ 'A', 'B']
      return unique;
    },
    eventtypes() {
      return this.event_types.filter((el) => {
        return el.value != "tour";
      });
    },
    // Todo filter states for requested to crew/office
    eventStates() {
      if (
        this.hasPoolPermission(this.poolEventPermissions) ||
        this.hasSystemPermission()
      ) {
        return this.event_states;
      } else {
        return this.publishedStates;
      }
    },
    ...mapGetters({
      event_types: "events/eventtypes",
      event_states: "events/eventstates",
      publishedStates: "events/publishedstates",
      events: "events/pg/list",
      user: "user/current",
      crewList: "crews/dropdown",
      poolEventPermissions: "user/roles/poolEventPermissions",
      hasPoolPermission: "user/roles/hasPoolPermission",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
  },
};
</script>
