<template>
  <div class="table-container">
    <VcaTable
      store="events"
      :list="pg_list"
      :colspan="9"
      :searchLabel="$t('events.list.search')"
    >
      <template v-slot:colspan>
        <col width="10%" />
        <col width="10%" />
        <col width="10%" />
        <col width="10%" />
        <col width="10%" />
        <col width="10%" />
        <col width="10%" />
        <col v-if="hasPermissions" width="10%" />
        <col width="1%" />
      </template>

      <template v-slot:desktop-header>
        <th class="vca-table-cell sortable" @click="sort('name')">
          <label> {{ $t("events.name.header") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('type_of_event')">
          <label> {{ $t("events.type") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('location.city')">
          <label> {{ $t("events.location.city") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('crew.name')">
          <label> {{ $t("events.crew.label") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('event_state.state')">
          <label> {{ $t("table.header.state") }} &varr;</label>
        </th>
        <th class="vca-table-cell">
          <label class="sortable" @click="sort('application.start_date')">
            {{ $t("events.application.start_date") }} &varr;</label
          >
          <label class="sortable" @click="sort('application.end_date')">
            {{ $t("events.application.end_date") }} &varr;</label
          >
        </th>
        <th class="vca-table-cell sortable" @click="sort('start_at')">
          <label> {{ $t("events.start_at.header") }} &varr;</label>
        </th>
        <th
          v-if="hasPermissions"
          class="vca-table-cell sortable"
          @click="sort('applications.total')"
        >
          <label>
            {{ $t("events.application.applications") }}
            &varr;</label
          >
        </th>
        <th class="vca-table-cell">
          <label>{{ $t("table.options") }}</label>
        </th>
      </template>

      <template v-slot:desktop-content>
        <tr
          class="vca-table-row"
          :class="{ last: index + 1 == pg.pageSize }"
          v-for="(res, index) in pg_list"
          :key="index"
        >
          <td class="vca-table-cell">
            <label> {{ res.name }}</label>
          </td>
          <td class="vca-table-cell">
            <label>
              {{ $t("events.eventtypes." + res.type_of_event + ".title") }}
              <span v-if="res.tour_id != ''"
                >({{ $t("events.eventtypes.tour.title") }})</span
              ></label
            >
          </td>
          <td class="vca-table-cell">
            <label> {{ res.location.city }}</label>
          </td>
          <td class="vca-table-cell">
            <label v-if="res.crew.name != ''">{{ res.crew.name }}</label>
            <label v-else>{{ $t("events.list.crew.office_hh") }}</label>
          </td>
          <td class="vca-table-cell">
            <label>
              {{ stateText(res) }}
              <span v-if="res.event_state.state == 'requested'">
                {{ requestedTo(res) }}
              </span>
            </label>
          </td>
          <td class="vca-table-cell">
            <label>
              {{ date(res.application.start_date) }} -
              {{ date(res.application.end_date) }}</label
            >
          </td>
          <td class="vca-table-cell">
            <label> {{ date(res.start_at) }}</label>
          </td>
          <td v-if="hasPermissions" class="vca-table-cell">
            <label v-if="canEdit(res)" class="table_participations">
              <vca-row
                :class="{
                  green:
                    res.applications.confirmed ==
                    res.application.supporter_count,
                  red:
                    res.applications.confirmed <
                    res.application.supporter_count,
                  orange:
                    res.applications.confirmed >
                    res.application.supporter_count,
                }"
                >{{ res.applications.confirmed }}
                {{ $t("events.participations.status.confirmed") }}</vca-row
              >
              <vca-row
                >{{ res.applications.requested }}
                {{ $t("events.participations.status.requested") }}</vca-row
              >
              <vca-row
                >{{ res.applications.rejected }}
                {{ $t("events.participations.status.rejected") }}</vca-row
              >
              <vca-row
                >{{ res.applications.withdrawn }}
                {{ $t("events.participations.status.withdrawn") }}</vca-row
              >
            </label>
          </td>
          <td class="vca-table-cell">
            <label class="table-options">
              <img
                v-if="(canEdit(res) || isEventAsp(res)) && res.isPublished"
                class="editable"
                src="@/assets/icons/icon-supporters.png"
                @click="setCurrentParticipations(res)"
                :title="$t('events.participations.header')"
                :alt="$t('events.participations.header')"
              />
              <img
                v-if="canEdit(res) || isEventAsp(res)"
                class="editable"
                src="@/assets/icons/edit.png"
                @click="setCurrentEdit(res)"
                :title="$t('button.edit')"
                :alt="$t('button.edit')"
              />
              <img
                class="editable"
                v-if="res.hasLocation"
                @click="setMap(res)"
                src="@/assets/icons/map.png"
                :title="$t('button.map')"
                :alt="$t('button.map')"
              />
              <img
                class="editable"
                v-if="res.isPublished"
                src="@/assets/icons/open.png"
                @click="setCurrentView(res)"
                :title="$t('events.view.page')"
                :alt="$t('events.view.page')"
              />
              <img
                @click="setCurrent(res)"
                class="editable apply-filter"
                src="@/assets/icons/public.svg"
                :title="$t('button.view')"
                :alt="$t('button.view')"
              />
              <img
                v-if="canEdit(res) && isFinished(res)"
                @click="setCopy(res)"
                class="editable"
                src="@/assets/icons/copy.png"
                :title="$t('button.copy')"
                :alt="$t('button.copy')"
              />
              <img
                v-if="hasSystemPermission('admin')"
                @click="doSync(res)"
                class="editable"
                src="@/assets/icons/sync.png"
                :title="$t('button.sync')"
                :alt="$t('button.sync')"
              />
            </label>
          </td>
        </tr>
      </template>

      <template v-slot:mobile-header>
        <div><img width="20px" src="~@/assets/icons/sort.png" /></div>
        <div class="sortable" @click="sort('name')">
          <label>{{ $t("table.header.name") }} &varr;</label>
        </div>
        <div class="sortable" @click="sort('start_at')">
          <label> {{ $t("events.start_at.header") }} &varr;</label>
        </div>
        <div class="sortable" @click="sort('event_state.state')">
          <label> {{ $t("table.header.state") }} &varr;</label>
        </div>
      </template>

      <template v-slot:mobile-content>
        <tr
          class="vca-table-row"
          :class="{ last: index + 1 == pg.pageSize }"
          v-for="(res, index) in pg_list"
          @click="setCurrent(res)"
          :key="index"
        >
          <td class="vca-table-cell">
            <vca-column>
              <vca-row>
                <div class="vca-left vca-table-index">
                  <span class="bold">{{ res.name }}</span>
                </div>
                <div class="vca-right vca-table-index">
                  {{ datetime(res.start_at) }}<br />{{ datetime(res.end_at) }}
                  <div>
                    <img
                      :src="res.eventIcon"
                      :title="res.type_of_event_translation"
                      :alt="res.type_of_event_translation"
                    />
                  </div>
                </div>
              </vca-row>
              <vca-row>
                <div class="vca-left vca-table-index">
                  {{ $t("events.crew.label") }}:&nbsp;
                  <span v-if="res.crew.name != ''">{{ res.crew.name }}</span>
                  <span v-else> {{ $t("events.list.crew.office_hh") }}</span>
                </div>
              </vca-row>
              <vca-row>
                <div class="vca-left vca-table-index">
                  {{ $t("table.header.state") }}:
                  {{ stateText(res) }}
                </div>
              </vca-row>
              <vca-row>
                <div class="vca-left vca-table-index">
                  {{ $t("events.application.label") }}:
                  {{ date(res.application.start_date) }} -
                  {{ date(res.application.end_date) }}
                </div>
              </vca-row>
            </vca-column>
          </td>
        </tr>
      </template>
    </VcaTable>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import VcaTable from "@/components/utils/VcaTable";
export default {
  name: "EventsTable",
  components: { VcaTable },
  data() {
    return {
      store: "events/pg",
      initial: true,
      do_filter: false,
      currentFilter: {
        nvm_state: "",
        active_state: "",
        pool_roles: [],
        crew_id: [],
      },
    };
  },
  computed: {
    hasPermissions() {
      return (
        this.hasSystemPermission() ||
        this.hasPoolPermission(this.poolEventPermission)
      );
    },
    filter: {
      set(value) {
        this.pg.filterObject = value;
      },
      get() {
        return this.pg.filterObject;
      },
    },
    ...mapState({
      pg(state, getters) {
        if (this.store) {
          return getters[`${this.store}/pagination`];
        }
      },
    }),
    ...mapGetters({
      hasSystemPermission: "user/roles/hasSystemPermission",
      hasPoolPermission: "user/roles/hasPoolPermission",
      poolEventPermissions: "user/roles/poolEventPermissions",
      pg_list: "events/pg/list",
      user: "user/current",
    }),
  },
  methods: {
    getMappedState(value) {
      let mappedState = value.event_state.state;
      if (value.event_state.state == "requested") {
        mappedState =
          value.event_state.crew_confirmation == ""
            ? "requested_crew"
            : "requested_internal";
      }
      return mappedState;
    },
    stateText(value) {
      let event_state_text = this.$t(
        "events.list.event_states." + this.getMappedState(value)
      );

      if (this.hasSystemPermission() && value.event_state.state == "closed") {
        event_state_text = this.$t("events.list.event_states.closed");
      }

      if (value.event_state.state == "requested") {
        event_state_text =
          value.event_state.internal_confirmation == ""
            ? this.$t("events.list.event_states.requested_internal")
            : this.$t("events.list.event_states.requested_crew");
      }
      return event_state_text;
    },
    isFinished(res) {
      return (
        res.event_state &&
        (res.event_state.state == "finished" ||
          res.event_state.state == "closed")
      );
    },
    canEdit(event) {
      return (
        this.hasSystemPermission() ||
        (this.hasPoolPermission(this.poolEventPermissions) &&
          this.user.crew.crew_id == event.crew.id)
      );
    },
    isEventAsp(value) {
      return (
        this.user && value.event_asp_id && this.user.id == value.event_asp_id
      );
    },
    requestedTo(value) {
      return value.event_state.internal_confirmation != ""
        ? this.$t("events.list.event_states.to_crew")
        : this.$t("events.list.event_states.to_office");
    },
    setCurrent(value) {
      if (this.current && this.current.id == value.id) {
        this.$store.commit("events/current", null);
      } else {
        this.$store.commit("events/current", value);
      }
    },
    setCopy(value) {
      this.$store.commit("events/copy", value);
      this.$router.push({ path: "/events/add" });
    },
    setCurrentEdit(value) {
      this.$router.push({ path: "/events/edit/" + value.id });
    },
    setCurrentParticipations(value) {
      this.$router.push({ path: "/events/participants/" + value.id });
    },
    setCurrentView(value) {
      this.$router.push({ path: "/events/" + value.id });
    },
    setMap(value) {
      this.showMap = true;
      this.map = value;
    },
    async sort(col) {
      await this.$store.dispatch({
        type: this.store + "/sort",
        col,
      });
      return;
    },
  },
};
</script>
